import { Modal } from "antd";


export const modalError = (msg) => {
    Modal.error({
        title: 'Notification',
        content: msg
    });
};


export const modalSuccess = (msg) => {
    Modal.success({
        title: 'Notification',
        content: msg
    });
};
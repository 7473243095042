import { HomeOutlined, BookOutlined, InboxOutlined, TeamOutlined, SettingOutlined, ShopOutlined, AppstoreOutlined, CopyrightOutlined, FormOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function MenuSideBars() {

    const navigate = useNavigate();
    const [item, setItem] = useState();

    useEffect(() => {
        var menu = [getItem("Data Auditor", "auditor", getIcon('team'))];
        setItem(menu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIcon = (icon_name) => {
        switch (icon_name) {
            case 'home':
                return <HomeOutlined />;
            case 'pos':
                return <ShopOutlined />;
            case 'setoutletproduct':
                return <ShopOutlined />;
            case 'master':
                return <AppstoreOutlined />;
            case 'report':
                return <BookOutlined />;
            case 'team':
                return <TeamOutlined />;
            case 'point':
                return <CopyrightOutlined />;
            case 'settings':
                return <SettingOutlined />;
            case 'membership':
                return <TeamOutlined />
            case 'service':
                return <FormOutlined />
            case 'penjualan':
                return <InboxOutlined />
        }
    }

    return (
        <Sider
            width={200}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={['auditor']}
                // selectedKeys={['pos']}
                // defaultOpenKeys={['sub1']}
                style={{
                    height: '100%',
                    borderRight: 0,
                    fontSize: 11
                }}
                items={item}
                onClick={({ key }) => navigate('/' + key)}
            />
        </Sider>
    );
}

export default MenuSideBars;
import { HashRouter, Route, Routes } from "react-router-dom";
import ProtectedLayout from "../Component/Main/ProtectedLayout";
import Login from '../Views/Auth/Login';
import Auditor from "../Views/Auditor/Auditor";
import ReportPage from "../Views/Report/ReportPage";

function Router({ history }) {
  return (
    <HashRouter history={history}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/report/:id" element={<ReportPage />} />
        <Route path="/" element={<ProtectedLayout />} >
          <Route path="/" element={<Auditor />} />
          <Route path="/auditor" element={<Auditor />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Route>
      </Routes>
    </HashRouter>
  );
}


export default Router;
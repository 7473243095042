
import { Alert, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
// import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import { currencyFormat } from '../../Utils/formatCurrency';
import dayjs from 'dayjs';


function Auditor() {
    const token = useSelector((state) => state.user.token);
    //DATA
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [isModalDetail, setIsModalDetail] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Auditor';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'No. Lai',
            dataIndex: 'no_lai',
            key: "no_lai",
            sorter: true,
        },
        {
            title: 'Nama KAP',
            dataIndex: 'nama_kap',
            key: "nama_kap",
            sorter: true,
        },
        {
            title: 'Nama Klien',
            dataIndex: 'nama_klien',
            key: "nama_klien",
            sorter: true,
        },
        {
            title: 'Tgl. LAI',
            dataIndex: 'tgl_lai',
            key: "tgl_lai",
            sorter: true,
        },
        {
            title: 'AP Penanggung jawab',
            dataIndex: 'ap_pic',
            key: "ap_pic",
            sorter: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('detail', record)}>
                            <UnorderedListOutlined />
                        </Button>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/auditor?search=' + search + '&page=' + page.pagination.current, 'auditor');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setData(res.data.rows);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.count
                    }
                });
                setLoading(false);
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = (method, url, values) => {
        setLoading(true);
        setIsAlert(false);

        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/auditor/' + v.auditor_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                nama_kap: data.nama_kap,
                nama_klien: data.nama_klien,
                periode: data.periode,
                no_lai: data.no_lai,
                tgl_lai: dayjs(data.tgl_lai, 'DD-MM-YY'),
                ap_pic: data.ap_pic,
                opini: data.opini,
                total_aset: data.total_aset,
                laba_rugi: data.laba_rugi,
            })
            setIsModal(true);
        } else if (type === 'detail') {
            setdataModal(data)
            setIsModalDetail(true)
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        ev.tgl_lai = ev.tgl_lai.format('YYYY-MM-DD');
        if (dataModal != null) {
            ev.auditor_id = dataModal.auditor_id;
            postApi('PUT', '/auditor', ev);
        } else {
            postApi('POST', '/auditor', ev);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        setIsModalDetail(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Auditor</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Auditor</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            {/* detail data */}

            <Modal title="Data Auditor" open={isModalDetail} onCancel={() => closeModal()} onOk={() => closeModal()} >
                {
                    dataModal !== null ?
                        <>
                            <h2 style={{ textAlign: "center" }}>QRCode</h2>
                            <div style={{ textAlign: "center" }}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                                    value={"https://lai-verifikasi.com/#/report/" + dataModal.auditor_id}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <hr />
                            <h4>Informasi</h4>
                            <div>A. Nama KAP : {dataModal.nama_kap}</div>
                            <div>B. Nama Klien : {dataModal.nama_klien}</div>
                            <div>C. Periode : {dataModal.periode}</div>
                            <div>D. No. LAI : {dataModal.no_lai}</div>
                            <div>E. Tgl. LAI : {dataModal.tgl_lai}</div>
                            <div>F. AP Penanggungjawab : {dataModal.ap_pic}</div>
                            <div>G. Opini : {dataModal.opini}</div>
                            <div>H. Total Aset : {currencyFormat(dataModal.total_aset)}</div>
                            <div>I. Laba/Rugi Bersih : {currencyFormat(dataModal.laba_rugi)}</div>
                        </>
                        :
                        null
                }
            </Modal>

            {/* form data  */}
            <Modal title="Data Auditor" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="nama_kap"
                        label="Nama KAP"
                        rules={[
                            {
                                required: true,
                                message: 'Nama KAP kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="nama_klien"
                        label="Nama Klien"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Klien kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="periode"
                        label="Periode"
                        rules={[
                            {
                                required: true,
                                message: 'Periode kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="no_lai"
                        label="No. LAI"
                        rules={[
                            {
                                required: true,
                                message: 'No. LAI kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="tgl_lai"
                        label="Tgl. LAI"
                        rules={[
                            {
                                required: true,
                                message: 'Tgl. LAI kosong!'
                            },
                        ]}
                    >
                        <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        name="ap_pic"
                        label="AP Penanggung Jawab"
                        rules={[
                            {
                                required: true,
                                message: 'AP Penanggung Jawab kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="opini"
                        label="Opini"
                        rules={[
                            {
                                required: true,
                                message: 'Opini kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="total_aset"
                        label="Total Asset"
                        rules={[
                            {
                                required: true,
                                message: 'Total Asset kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Cth : Rp. 1.000.000' />
                    </Form.Item>
                    <Form.Item
                        name="laba_rugi"
                        label="Laba Rugi"
                        rules={[
                            {
                                required: true,
                                message: 'Laba Rugi kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Cth : Rp. 1.000.000' />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


export default Auditor;
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Image, Popover, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TOKEN, UPDATE_USER } from '../../../../Redux/actions/userActions';

function Headers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //Menu Profile
    const text = <Button type="link" ><span>Profile</span></Button>;
    const content = (
        <div>
            <Button type="link" danger onClick={() => logout()}>
                Log Out
            </Button>
        </div>
    );


    //Function
    const logout = () => {
        dispatch(UPDATE_TOKEN());
        dispatch(UPDATE_USER());
        navigate('/login');
    }


    return (
        <Header className="header" style={{backgroundColor:"#1E90FF"}}>
            <Row>
                <Col span={16} >
                    <b style={{fontSize:15,color:'#FFF'}}>Admin Auditor</b>
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Popover placement="bottom" title={text} content={content} trigger="click">
                        <Avatar icon={<UserOutlined />} />
                    </Popover>
                </Col>
            </Row>
        </Header>
    );
}

export default Headers;
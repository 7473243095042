import React from 'react';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useParams } from 'react-router-dom';
import { currencyFormat } from '../../Utils/formatCurrency';
import { Helmet } from 'react-helmet-async';
import { isMobile } from 'react-device-detect';
import { Layout, Flex } from 'antd';
const { Header, Footer, Sider, Content } = Layout;

const ReportPage = () => {
    // const token = useSelector((state) => state.user.token);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        getApi('GET', '/auditor/' + id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = (method, url) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setData(res.data);
                setLoading(false);
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    function DateFormat() {
        const now = new Date();

        const date = `0${now.getDate()}`.slice(-2); // Mendapatkan tanggal dan memastikan formatnya selalu dua digit
        const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        const month = monthNames[now.getMonth()]; // Mendapatkan nama bulan dalam Bahasa Indonesia
        const year = now.getFullYear();
        const hours = `0${now.getHours()}`.slice(-2);
        const minutes = `0${now.getMinutes()}`.slice(-2);
        const seconds = `0${now.getSeconds()}`.slice(-2);

        const formattedDate = `hari ${date} ${month} ${year} jam ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    const browser = () => {
        return (
            <div style={{ backgroundColor: "#F2F2F2", padding: 30, minHeight: '100vh' }}>
                <Helmet>
                    <title>Verifikasi LAI</title>
                </Helmet>
                {
                    data !== null &&
                    <div style={{ backgroundColor: "#FFF", borderRadius: 10, padding: 20 }}>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontWeight: "bold", fontSize: 18 }}>KEMENTERIAN KEUANGAN REPUBLIK INDONESIA</div>
                            <div style={{ fontWeight: "bold", fontSize: 18 }}>SEKRETARIAT JENDERAL</div>
                            <div style={{ fontWeight: "bold", fontSize: 18 }}>PUSAT PEMBINAAN PROFESI KEUANGAN</div>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <div>Laporan Auditor Independen <i><b>telah terdaftar</b></i> pada aplikasi Pelita di Pusat Pembinaan Profesi Keuangan dengan informasi sebagai berikut:</div>
                        <br />
                        <div>A. Nama KAP : {data.nama_kap}</div>
                        <div>B. Nama Klien : {data.nama_klien}</div>
                        <div>C. Periode : {data.periode}</div>
                        <div>D. No. LAI : {data.no_lai}</div>
                        <div>E. Tgl. LAI : {data.tgl_lai}</div>
                        <div>F. AP Penanggungjawab : {data.ap_pic}</div>
                        <div>G. Opini : {data.opini}</div>
                        <div>H. Total Aset : {currencyFormat(data.total_aset)}</div>
                        <div>I. Laba/Rugi Bersih : {currencyFormat(data.laba_rugi)}</div>
                        <br />
                        <div><i>"Disclaimer: Semua informasi dalam QR Code dibuat oleh KAP yang bersangkutan, PPPK tidak bertanggung jawab atas kesalahan informasi yang disampaikan KAP."</i></div>
                        <br />
                        <hr />
                        <div style={{ textAlign: "center" }}>
                            <b style={{ fontSize: 14 }}>Untuk informasi lebih lanjut silahkan hubungi (021) 3505112 atau email ke <a href="kemenkeu.prime@kemenkeu.go.id">kemenkeu.prime@kemenkeu.go.id</a></b>
                            <br />
                            <p style={{ fontSize: 12 }}>dibuat oleh sistem pada {DateFormat()}</p>
                        </div>
                    </div>
                }
            </div>
        );
    }

    const mobile = () => {
        return (
            <>
                <Header style={{
                    textAlign: 'center',
                    color: '#333',
                    height: 64,
                    fontSize:15,
                    fontWeight:'bold',
                    paddingInline: 48,
                    lineHeight: '64px',
                    backgroundColor: '#FFF',
                    borderBottomWidth: 1,
                    borderBottomColor:"#CCC"
                }}>Verifikasi LAI</Header>
                <div style={{ backgroundColor: "#F2F2F2", padding: 30, minHeight: '100vh' }}>
                    <Helmet>
                        <title>Verifikasi LAI</title>
                    </Helmet>
                    {
                        data !== null &&
                        <div style={{ backgroundColor: "#FFF", borderRadius: 10, padding: 20 }}>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ fontWeight: "bold", fontSize: 18 }}>KEMENTERIAN KEUANGAN REPUBLIK INDONESIA</div>
                                <div style={{ fontWeight: "bold", fontSize: 18 }}>SEKRETARIAT JENDERAL</div>
                                <div style={{ fontWeight: "bold", fontSize: 18 }}>PUSAT PEMBINAAN PROFESI KEUANGAN</div>
                            </div>
                            <br />
                            <hr />
                            <br />
                            <div>Laporan Auditor Independen <i><b>telah terdaftar</b></i> pada aplikasi Pelita di Pusat Pembinaan Profesi Keuangan dengan informasi sebagai berikut:</div>
                            <br />
                            <table>
                                <tr>
                                    <td>A.</td>
                                    <td><b>Nama KAP</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{data.nama_kap}</td>
                                </tr>
                                <tr>
                                    <td>B.</td>
                                    <td><b>Nama Klien</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{data.nama_klien}</td>
                                </tr>
                                <tr>
                                    <td>C.</td>
                                    <td><b>Periode</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{data.periode}</td>
                                </tr>
                                <tr>
                                    <td>D.</td>
                                    <td><b>No. LAI</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{data.no_lai}</td>
                                </tr>
                                <tr>
                                    <td>E.</td>
                                    <td><b>Tgl. LAI</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{data.tgl_lai}</td>
                                </tr>
                                <tr>
                                    <td>F.</td>
                                    <td><b>AP Penanggungjawab</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{data.ap_pic}</td>
                                </tr>
                                <tr>
                                    <td>G.</td>
                                    <td><b>Opini</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{data.opini}</td>
                                </tr>
                                <tr>
                                    <td>H.</td>
                                    <td><b>Total Aset</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{currencyFormat(data.total_aset)}</td>
                                </tr>
                                <tr>
                                    <td>I.</td>
                                    <td><b>Laba/Rugi Bersih</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{currencyFormat(data.laba_rugi)}</td>
                                </tr>
                            </table>
                            <br />
                            <div><i>"Disclaimer: Semua informasi dalam QR Code dibuat oleh KAP yang bersangkutan, PPPK tidak bertanggung jawab atas kesalahan informasi yang disampaikan KAP."</i></div>
                            <br />
                            <hr />
                            <div style={{ textAlign: "center" }}>
                                <b style={{ fontSize: 14 }}>Untuk informasi lebih lanjut silahkan hubungi (021) 3505112 atau email ke <a href="kemenkeu.prime@kemenkeu.go.id">kemenkeu.prime@kemenkeu.go.id</a></b>
                                <br />
                                <p style={{ fontSize: 12 }}>dibuat oleh sistem pada {DateFormat()}</p>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (isMobile) {
            return mobile();
        }
        return browser();
    }

    return (
        <>
            {renderContent()}
        </>
    );
};

export default ReportPage;

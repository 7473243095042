import axios from "axios"
export const API_URL = process.env.REACT_APP_CORE_API;

const appHeaderProperties = {
  Accept: 'application/json',
}

export const apiCall = async ({ method, url, data = "", baseURL }, navigation) => {
  let head = {
    ...appHeaderProperties,
    ...data.headers,
  };
  try {
    const response = await axios({
      baseURL: baseURL || API_URL,
      method: method,
      url: url,
      data: data.data || "",
      headers: head || "",
      params: data.params || "",
      'axios-retry': {
        retries: 0
      }
    });
    return response.data;
  } catch (error) {
    console.log(error)
  }
};
import { Button, Card, Form, Input, Row, } from 'antd';
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { useNavigate } from 'react-router';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useDispatch } from 'react-redux';
import { MENU_USER } from '../../Redux/actions/userActions';

function Login() {
    //LIBRARY
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //FUNCTION 
    const onLogin = (values) => {
        const dataReq = {
            method: "POST",
            url: '/auth/login',
            data: {
                data: values,
                headers: null
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                dispatch(
                    MENU_USER(
                        null,
                        JSON.stringify(res.data.data),
                        res.data.token
                    )
                )
                navigate('/auditor');
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e)
            modalError('(Internal Server Error)');
        })
    }

    const onFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Card>
                <h2>Login Admin</h2>
                <Form
                    name="login"
                    onFinish={onLogin}
                    onFinishFailed={onFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="user"
                        rules={[{ required: true, message: 'E-mail anda kosong!' }]}
                    >
                        <Input
                            placeholder="E-mail"
                            prefix={<UserOutlined />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Password anda kosong!' }]}
                    >
                        <Input.Password
                            placeholder="input password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            prefix={<LockOutlined />}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Button type='primary' block htmlType="submit">Login</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}


export default Login;